.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .mantine-176n6q4 {
  padding: 24px !important;
} */
.mantine-176n6q4 {
  padding: 0px !important;
}
.mantine-rhsza5 {
  -webkit-tap-highlight-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 11px;
  height: 20px;
  line-height: 18px;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0 10.666666666666666px;
  box-sizing: border-box;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: auto;
  text-transform: uppercase;
  border-radius: 32px;
  font-weight: 700;
  letter-spacing: 0.25px;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  background: linear-gradient(45deg, #fab005 0%, #fa5252 100%);
  color: #fff;
  border: 0px solid transparent;
  position: absolute;
  top: 10px;
  right: -7px;
  pointer-events: none;
}

/* .ant-upload-drag {
  height: 0px !important;
  width: 85% !important;
  padding: 180px !important;
  margin: 66px !important;
}

.ant-upload-list-item-container {
  padding: 50px;
  margin: 10px;
} */

.ant-upload-wrapper .ant-upload-drag {
  width: auto !important;
}

.ant-upload-list-item-container {
  padding: 35px !important;
}

/* .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  //padding: 30px !important;
} */

.ant-list {
  background: white !important;
}
.addButton {
  display: flex;
  justify-content: end;
  padding: 6px;
}

.removeMargin {
  padding: 0 !important;
  margin: 0 !important;
}

.firstHalf {
  width: 100%;
  height: auto;

  background: white;
  border-radius: 16px;
}

.padding {
  padding: 20px;
}

.secondHalf {
  position: relative;
  width: 100%;
  display: flex;
  height: 30vh;

  background: transparent;
  border-radius: 16px;
}

.secondHalfs {
  position: relative;
  width: 100%;
  display: flex;
  height: 20vh;
  background: transparent;
  border-radius: 10px;
}

.custom-shape-divider-bottom-1671865759 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  border-radius: 16px;
}

.custom-shape-divider-bottom-1671865759 svg {
  position: relative;
  display: block;
  width: calc(150% + 1.3px);
  height: 229px;
}

.custom-shape-divider-bottom-1671865759 .shape-fill {
  fill: #fab005;
  border-radius: 16px;
  opacity: 0.6;
}

.shape-fills {
  fill: #228be6;
  border-radius: 16px;
  opacity: 0.6;
}

.btn:disabled,
.btn.disabled {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
.btn:disabled:hover,
.btn:disabled:focus,
.btn.disabled:hover,
.btn.disabled:focus {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}

/* .mantine-iwiy74 {
  color: white !important;
}

.mantine-1y4tb34 {
  color: white !important;
} */

.mantine-heo0gy {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.services {
  padding-top: 130px;
  position: relative;
}

.services:after {
  content: "";
  background-image: url(./Pages/assets/main.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 1px;
  bottom: -448px;
  width: 261px;
  height: 468px;
  z-index: 0;
}

.services:before {
  content: "";
  /* background-image: url(./Pages/assets/services-left-dec.png); */
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 60;
  bottom: -300px;
  width: 477px;
  height: 368px;
  z-index: 0;
}

/* .contact-us::before {
    content: url(../images/contact-left.jpg);
    top: -60px;
    left: 0;
    position: absolute;
    width: 103px;
    height: 464px;
    z-index: -1; */
.mantine-5f6x53 {
  gap: 27px !important;
}

.mantine-1nqidi4 {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: rgb(0, 0, 0);
  padding: 25px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(222, 226, 230);
  transition: box-shadow 100ms ease 0s, transform 100ms ease 0s;
}

.ant-table-content::-webkit-scrollbar {
  width: 2px;
  height: 15px;
  /* width of the entire scrollbar */
}

.ant-table-content::-webkit-scrollbar-track {
  background: orange;

  /* color of the tracking area */
}

.input:checked + .mantine-g1sngq {
  color: #0ede45 !important;
}

/* .mantine-y1lrl8 {
  color: orange !important;
} */

.mantine-gtpims {
  padding: 2px;
}

.mantine-1bk1nos {
  padding: 60px;
}

.ant-table-content::-webkit-scrollbar-thumb {
  /* background-color: #3f96ff;
  /* color of the scroll thumb */
  /* border-radius: 5px;
  /* roundness of the scroll thumb */
  border: 3px solid rgb(191, 191, 194);

  height: 20px; /* Can be anything */
  position: relative;
  width: 20px;
  margin: 60px 0 20px 0; /* Just for demo spacing */
  background: #555;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 10px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  /* creates padding around scroll thumb */
}

.ant-table-row-expand-icon {
  background: #fa5252 !important;
  color: #fff !important;
  /* // animation: tilt-shaking 0.15s infinite; */
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  border-radius: 8px !important;
  border-color: #339bf0 !important;
}

/* .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  padding: -50px !important;
} */

/* 

Pdf Styling...

*/

#ResumeContainer {
  margin: auto;
  width: 100%;
  /* border: yellow solid 3px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border: #238be6 solid 3px;
  border-radius: 5px;
}

.PDFPageOne {
  margin-bottom: 25px;
}

.PDFPage > canvas {
  max-width: 591px;
  height: auto !important;
}

.scan {
  /* border: dashed 0.25em rgb(90, 85, 85); */
  transform: translate(-5%);
}

.scan::after {
  content: "";
  background: #238be6;
  width: 0.25em;
  height: 4.5em;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  opacity: 7;
  z-index: 2;
  animation: 2s infinite ease-in-out roll;
}

.button-34 {
  background: #5e5df0;
  border-radius: 999px;
  box-shadow: #5e5df0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

@keyframes roll {
  0% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(-50px);
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.logoIcon {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-85 {
  padding: 0.1em 0em;
  width: 170px;
  height: 48px;
  border: none;
  margin-top: 41px;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  tw-ring-color: none !important;
  --border-color: none !important;
  --tw-ring-shadow: !important;
}

.mantine-661zth {
  margin-top: 30px;
}
.mantine-12ctv7x {
  padding-left: 12px !important;
}

/* .mantine-1a78l0v {
  margin: 15px;
} */

.labelstyle {
  display: none !important;
  margin: -12px !important;
  padding-top: -1px !important;
  margin-top: -1px;
}

.mantine-1myybh9 {
  margin-bottom: 27px !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.mantine-1fmzaof {
  margin-bottom: 27px !important;
}
